export const indexContactForm = () => {
    Alpine.data('indexContactForm', () => {
        let el;

        const data = {
            name: '',
            email: '',
            note: '',
        };

        return {
            data,
            errors: Object.fromEntries(
                Object.keys(data).map(key => [key, null])
            ),
            touched: Object.fromEntries(
                Object.keys(data).map(key => [key, false])
            ),
            init() {
                el = this.$el;

                this.$watch('data.note', () => {
                    const scrollHeight = this.$refs.note.scrollHeight;
                    if (scrollHeight > this.$refs.note.clientHeight)
                        this.$refs.note.style.height = `${scrollHeight + 4}px`;
                });
            },
            async onSubmit() {
                try {
                    const result = await axios.post(el.action, this.data);
                    const redirect = result.data.redirect;

                    if (redirect) {
                        window.location.href = redirect;
                        return;
                    }
                } catch (err) {
                    const errors = err.response.data.errors;

                    Object.keys(this.errors).forEach(key => this.errors[key] = null);

                    if (errors) {
                        Object.entries(errors).forEach(
                            ([key, errors]) => this.errors[key] = errors[0]
                        );
                    }
                }

                Object.keys(data).forEach(key => {
                    this.touched[key] = false;
                    console.log(key, false);
                });
            },
        };
    });
};
